/**
 * Checks if there is a valid completed refund offer and redirect if not
 */
export default defineNuxtRouteMiddleware((to) => {
    const localeRoute = useLocaleRoute();
    const refundStore = useRefundStore();

    // If there are no completed refund offers or the stored offer doesnt match the current booking, return to refund start
    if (!refundStore.refundCompletedOffers.length || refundStore.refundCompletedOffers.some(o => o.bookingId !== to.params.bookingId)) {
        return navigateTo(localeRoute({ name: 'esav-refund-step-1', params: { bookingId: to.params.bookingId } }));
    }
});
